import React, { useContext } from 'react';
import context from '../context';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import RateBar from './ratebar';
import Logo from './logo';

const Header = styled.header`
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const Navigation = styled.nav`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el contenido horizontalmente */
`;

export default ({ dark }) => {
  const state = useContext(context);

  return (
    <Header className="d-none d-lg-block">
      <Container>
        <Navigation>
          <AniLink paintDrip hex={state.primaryColor} to="/" duration={0.5}>
            <Logo dark={dark} light={!dark} />
          </AniLink>
        </Navigation>
      </Container>
    </Header>
  );
};