import React, { useContext, useState, Fragment } from 'react';
import context from '../context';
import styled from 'styled-components';
import HamburgerMenu from 'react-hamburger-menu';
import { Container } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';

import Logo from './logo';
import RateBar from './ratebar';
import { NavLink } from '../styled-components';

const Header = styled.header`
  background-color: transparent;
  position: flex;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0.15rem 0; /* Compactamos aún más el header */
  z-index: 1000;
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el contenido */
  height: 50px; /* Ajustamos la altura para compactar */
`;

const NavPanel = styled.div`
  background-color: ${props => props.theme.primaryColor};
  height: calc(100vh - 50px); /* Ajustamos la altura al header reducido */
  width: 100vw;
  transition: 500ms ease;
  position: fixed;
  left: ${props => props.visible ? "0" : "100vw"};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: ${props => props.horizontal ? "flex" : "block"};
  text-align: center;
`;

const NavItem = styled.li`
  font-size: 2rem;
`;

export default () => {
  const [open, setOpen] = useState(false);
  const state = useContext(context);

  return (
    <Fragment>
      <Header className="d-lg-none">
        <Container>
          <Navigation>
            <AniLink paintDrip hex={state.primaryColor} to="/" duration={0.5}>
              <Logo mobile />
            </AniLink>
          
          </Navigation>
        </Container>
      </Header>
      <NavPanel visible={open}>
        <RateBar />
        <NavList horizontal>
          <NavItem>
            <AniLink href={state.facebook} target="_blank" rel="noopener">
              <NavLink light>
                <FacebookOutlined />
              </NavLink>
            </AniLink>
          </NavItem>
          <NavItem>
            <AniLink href={state.instagram} target="_blank" rel="noopener">
              <NavLink light>
                <InstagramOutlined style={{ margin: "0 1rem" }} />
              </NavLink>
            </AniLink>
          </NavItem>
          <NavItem>
            <AniLink href={state.twitter} target="_blank" rel="noopener">
              <NavLink light>
                <TwitterOutlined />
              </NavLink>
            </AniLink>
          </NavItem>
        </NavList>
      </NavPanel>
    </Fragment>
  );
};